
body {
	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	background-color: #ededed;
	color: #666;
}

body .segment {
	color: #666;
}



.actions {
	width: 450px;
}

.ui.table thead th {
	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
}

td {
	font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 600;
}



.ui.list .content {
	font-weight: normal;
}

.ui.list .content .header {
	font-size: medium;
	line-height: 1.2em;
}

.ui.steps .step.active .title {
	color: #478D76;
}

.ui.primary.button {
	background-color: #267158;
}

.ui.primary.button:hover {
	background-color: #0E553E;
}

.ui.label.user {
	width: 130px;
	text-align: right;
}

.ui.field_with_errors {
	float: left;
}

.ui.labeled.input em {
	display: none;
}



.logo {
	font-family: 'Lato', sans-serif; font-weight: 600; font-style: normal;
	font-size: 1.2em;
	margin: 0;
	padding: 0;
	padding-top: 5pt;
	margin-right: 20pt;
}

.logo a {
	color: white;
}

.logo a:hover {
	background-color: transparent;
}

.app_link_row {
	padding-top: 0;
}

.app_links ul {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	padding-left: 2em;
	padding-right: 2em;
	list-style-type: none;
	font-family: 'Lato', sans-serif; font-weight: 600; font-style: normal;
	font-size: 1.2em;
}

.app_links li {
	display: inline;
	padding-right: 2em;
}

.app_links li a {
	background-color: transparent;
}



.masthead .active .text {
	color: white;
}

.masthead .icon {
	color: white;
}

.masthead .menu .icon {
	color: black;
}


.ui.secondary.pointing.menu .item {
	border-bottom-width: 0px;
}

.masthead .dropdown .text {
	color: white;
}

.masthead .dropdown .text .icon {
	color: white;
}

.logoblock {
	font-size: 0.7em;
	vertical-align: text-top;
}


.header_links {
	padding-left: 20pt;
}

.server_label {
	color: #e8e8e8;
	background-color: #666;
}

.machine_icon {
	height: auto;
	max-width: 100%;
}

.response_icon {
	float: left;
	width: 1.2em;
	height: 1.2em;
	margin-right: 1em;
}

.response {
	min-height: 50pt;
	background-color: white;
}

.response_message {
	float: left;
	width: 50%;
}

.response_timer {
	float: right;
	width: 20%;
	text-align: right;
}

.countdown_value {
  	font-weight: bold;
  }


/** title page **/

.promo_title h4 {
	font-size: 2vw;
	margin-top: 20pt;
	margin-left: 20pt;
}

.promo_title h1 {
	font-size: 9vw;
	margin-bottom: 50pt;
}

.demo_animation {
	width: 100%;
}

.promo p {
	font-family: 'Merriweather', serif;
	font-style: normal;
	font-weight: 600;
	font-size: medium;
}

.promo .preamble {
	font-size: x-large;
	font-weight: 700;
}

.promo .small {
	font-size: xx-small;
	line-height: 1.2em;
	font-weight: 700;
}

.footer {
	padding-top: 20pt;
}

.footer p {
	font-size: small;
	font-weight: 500;
	margin-top: 20pt;
	margin-bottom: 20pt;
}

.footer a {
	text-decoration: underline;
}